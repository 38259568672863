// src/serviceWorkerRegistration.js

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(/^127(\.[0-9]{1,3}){3}$/)
);

// Check if the app is running as a PWA
const isPWA = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;

export function register() {
    window.addEventListener('load', () => {
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

        if (isPWA) {
            if (isLocalhost) {
                checkValidServiceWorker(swUrl);
            } else {
                registerValidSW(swUrl);
            }
        } else {
            unregister();
        }
    });
}

function registerValidSW(swUrl) {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register(swUrl).then((registration) => {
                console.log('Service Worker registered with scope:', registration.scope);
            }).catch((error) => {
                console.error('Service Worker registration failed:', error);
            });
    }
}

function checkValidServiceWorker(swUrl) {
    fetch(swUrl)
        .then((response) => {
            if (
                response.status === 404 ||
                !response.headers.get('content-type')?.includes('javascript')
            ) {
                navigator.serviceWorker.ready.then((registration) => {
                    registration.unregister();
                });
            } else {
                registerValidSW(swUrl);
            }
        })
        .catch(() => {
            console.log('No internet connection. Running in offline mode.');
        });
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
            registrations.forEach((registration) => registration.unregister());
        });
        console.log('Service Worker unregistered (not in PWA mode)');
    }
}
