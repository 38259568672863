import React, { createContext, useContext, useEffect, useState } from "react";

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const ws = new WebSocket(`wss://conn.tazroo.com`);

        ws.onopen = () => console.log("✅ WebSocket Connected");
        ws.onmessage = (event) => {
            const blob = event.data;
            
            const reader = new FileReader();
            reader.onload = function() {
                const message = JSON.parse(reader.result) || {};
                console.log('Received message:', message);
                setMessages((prev) => [...prev, message]);
            };
            
            reader.readAsText(blob);
        };

        ws.onclose = () => {
            console.log('connection closed');
        };
        ws.onerror = (err) => console.error("WebSocket Error", err);

        setSocket(ws);
        return () => ws.close();
    }, []);

    return (
        <WebSocketContext.Provider value={{
            socket, messages, sendMessage: (msg, extra) => {
                if (socket && socket.readyState === WebSocket.OPEN) {
                    socket.send(JSON.stringify({ type: "message", message: msg, extra }));
                }
            }
        }}>
            {children}
        </WebSocketContext.Provider>
    );
};

// Hook to use WebSocket in any component
export const useWebSocket = () => useContext(WebSocketContext);