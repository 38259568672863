import React, { useEffect, useRef, useState } from 'react'
import imageCompression from 'browser-image-compression';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { showLoader } from '../../utils/loaderUtils';
import services from '../../services/CustomServices';
import { useCategoryList } from '../../hooks/useCategoryList';
import MapPreviewComponent from '../mini/map/MapPreviewComponent';
import './addproduct.css'

export default function AddProductAdmin() {
    const { data: categories } = useCategoryList();
    const formRef = useRef();
    const dragDropBox = useRef();
    const loadingWin = useRef();
    const location = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();
    const [editId, setEditId] = useState();
    const [prData, setPrData] = useState();
    const [progress, setProgress] = useState();
    const [droppedFiles, setDroppedFiles] = useState({});
    const [uploadingStatus, setUploadingStatus] = useState();
    const SDT = JSON.parse(localStorage.getItem('sdt')) || false;
    const [pickupLocation, setPickupLocation] = useState(SDT?.location || false);

    const handleProductImgRemove = (e) => {
        const tr = e.target;
        const dropType = e.target.closest('.media-drag-drop').dataset.type;
        const fileName = tr.dataset.fname;

        setDroppedFiles((prev) => {
            const updatedFiles = { ...prev };

            if (!updatedFiles[dropType]) updatedFiles[dropType] = [];
            updatedFiles[dropType] = updatedFiles[dropType].filter((v) => {
                if (v.filePath) {
                    return v.fileName !== fileName
                } else return v.name !== fileName;
            });
            return updatedFiles;
        })
    }

    const fileChangeHandler = (e) => {
        const dropType = e.target.closest('.media-drag-drop').dataset.type;
        const newFiles = Array.from(e.target.files).filter((file, i) => file.type.includes("image") && i < 10);

        setDroppedFiles((prev) => {
            const updatedFiles = { ...prev };
            if (!updatedFiles[dropType]) {
                updatedFiles[dropType] = [];
            }
            newFiles.forEach((f) => {
                const findDuplicates = updatedFiles[dropType].find((v) => {
                    if (v.filePath) {
                        return v.fileName === f.name;
                    } else return v.name === f.name;
                });
                if (!findDuplicates) {
                    updatedFiles[dropType].push(f);
                }
            });
            return updatedFiles;
        });
    };

    const colorInpHandler = (e) => {
        const tr = e.target;
        const colorDisplay = tr.parentElement.querySelector('.inp-color-display');
        colorDisplay.innerHTML = '';

        if (tr.value !== '') {
            if (!colorDisplay.classList.contains('active')) colorDisplay.classList.add('active');
            tr.value.split(',').forEach((v) => {
                if (v === '' || v === ' ') return;
                const colorElm = document.createElement('span');
                colorElm.innerHTML = v.trim();
                colorDisplay.appendChild(colorElm)
            });
        } else {
            colorDisplay.classList.remove('active')
        }
    }

    const categorySubmitHandler = (e) => {
        const btn = e.target;
        const categoryInp = btn.previousElementSibling;

        if (categoryInp.value === '') {
            categoryInp.style.borderColor = 'var(--warning)';
        } else categoryInp.style = ''
        console.log(categoryInp.value);
    }

    const handleSubmit = async (e) => {
        const target = e.target;
        const form = formRef.current;
        const formData = new FormData(form);
        const allInputs = form.querySelectorAll('input, select, textarea');

        // process inputs and form data before sending to the server
        let tmpInpName = '', dataVerified = true;
        allInputs.forEach((inp) => {
            let { name, value } = inp;

            if(typeof value === 'string') value = value.trim();

            // show border red if empty
            if(value === '' && inp.required){
                inp.closest('.inp-box').style.borderColor = 'var(--warning)';
                dataVerified = false;
            }

            // remove empty key and values
            if(value === '' && !inp.required){
                formData.delete(name);
            }

            // filter and process custom fields
            if (inp.dataset.field && inp.dataset.field === 'custom') {
                formData.delete(name);
                if(name === '' || value === '') return;
                const nameSplit = name.split('_');
                const nameSecondSplit = name.split('-')[0].split('_');

                if(nameSplit.includes('name') || nameSecondSplit.includes('name')){
                    tmpInpName = value;
                }else if(nameSplit.includes('value') || nameSecondSplit.includes('value')){
                    formData.append(tmpInpName, value);
                }
            }
        });

        if(!dataVerified) return;

        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 700,
            useWebWorker: true
        };

        if (!droppedFiles['image']) {
            services.notification('error', 'Product image required', 'Minimum 2 product image is required', ['ok']);
            return;
        }
        target.classList.add('loading');
        loadingWin.current.classList.add('active');
        loadingWin.current.children[0].classList.add('loader');

        setUploadingStatus('Compressing files...')
        await Promise.all(
            Array.from(droppedFiles['image']).map(async (file) => {
                try {
                    if (file.filePath) {
                        formData.append('images', JSON.stringify(file));
                    } else {
                        const compressedFile = await imageCompression(file, options);
                        const newFile = new File([compressedFile], file.name, { type: compressedFile.type });
                        formData.append('files', newFile);
                    }
                } catch (error) {
                    console.error('Image compression failed', error);
                }
            })
        );

        formData.forEach((v, key) => {
            if (v === '') formData.delete(key);
        });

        // If editId exists, append it to formData
        if (editId) formData.append('editid', editId);
        try {
            formData.append('status', 'pending');
            if (SDT && SDT.id) formData.append('seller', SDT.id);
            setUploadingStatus(editId ? 'Updating, Please wait...' : 'Uploading, Please wait...');
            const productUpload = new XMLHttpRequest();
            productUpload.open('POST', process.env.REACT_APP_API_URL + '/api/v1/product', true);

            productUpload.upload.onprogress = (e) => {
                if (e.lengthComputable) {
                    const percent = Math.round((e.loaded / e.total) * 100);
                    setProgress(percent);
                } else {
                    console.error('Progress length not computable.');
                    setUploadingStatus('Something went wrong!');
                    services.notification('error', editId ? 'Update failed!' : 'Upload failed!', `Product ${editId ? 'upload' : 'update'} failed! please try again later.`, ['ok']);
                }
            };

            productUpload.onload = function () {
                if (productUpload.status === 200) {
                    try {
                        console.log(JSON.parse(this.responseText));
                        target.classList.remove('loading');
                        loadingWin.current.classList.remove('active');
                        loadingWin.current.children[0].classList.remove('loader');
                        setUploadingStatus(editId ? 'Update finished!' : 'Upload finished!');
                        services.notification('done', editId ? 'Update finished!' : 'Upload finished!', `Product ${editId ? 'upload' : 'update'} finished!`, ['ok']);
                    } catch (error) {
                        console.log(error);
                        setUploadingStatus('Something went wrong!');
                        services.notification('error', editId ? 'Update failed!' : 'Upload failed!', `Product ${editId ? 'upload' : 'update'} failed! please try again later.`, ['ok']);
                    }
                } else {
                    setUploadingStatus('Upload Failed, Please reload and try again');
                    services.notification('error', editId ? 'Update failed!' : 'Upload failed!', `Product ${editId ? 'upload' : 'update'} failed! please try again later.`, ['ok']);
                }
                if (id) {
                    navigate('/seller/panel/products');
                }
            };
            productUpload.send(formData);
        } catch (error) {
            console.log(error);
            services.notification('error', 'Something went wrong', `Something went wrong! please refresh the page`, ['ok']);
        }
    };

    const dragStartHandle = (e) => {
        e.dataTransfer.setData("indx", Array.prototype.indexOf.call(e.target.parentElement.children, e.target));
    };

    const selectChangeHandler = (e) => {
        if (e.target.name === 'category') {
            const requestForNewCategory = document.querySelector('.category-add-request');
            const mainPr = requestForNewCategory.closest('.products-add');

            const rect = requestForNewCategory.getBoundingClientRect();
            const categoryInpField = requestForNewCategory.parentElement;

            if (e.target.value === 'rq') {
                categoryInpField.style.height = 70 + rect.height + 'px';
                if (mainPr) mainPr.style.height = parseInt(getComputedStyle(mainPr).height) + (rect.height / 3) + 70 + 'px';
            } else {
                categoryInpField.style = '';
                if (mainPr) mainPr.style.height = parseInt(getComputedStyle(mainPr).height) - rect.height - 70 + 'px';
            };
        } else if (e.target.name === 'stock') {
            const totalStockInp = document.querySelector("[name='total_stock']").parentElement;

            if (e.target.value === 'out') {
                totalStockInp.parentElement.style.gap = '0';
                totalStockInp.style.width = '0';
            } else {
                totalStockInp.style = '';
                totalStockInp.parentElement.style = '';
            };
        }else if(e.target.name === 'location'){
            const hiddenMapContainer = e.target.closest('.inp-box').querySelector('.hidden-loc-map');
            const hiddenChild = hiddenMapContainer.children[0].getBoundingClientRect();
            if(e.target.value === 'add_loc'){
                hiddenMapContainer.style.height = hiddenChild.height + 'px';
            }else{
                hiddenMapContainer.style.height = 0 + 'px';
            }
        }
        setPrData({ ...prData, [e.target.name]: e.target.value });
    }

    const addCustomFieldHandler = (e) => {
        const tr = e.target;
        const parent = tr.closest('.add-a-custom-field');
        const hiddenCustomField = parent.querySelector('.custom-hidden-field');
        const customFieldsContainer = hiddenCustomField.closest('.custom-hidden-fields-container');
        const boudingRect = hiddenCustomField.getBoundingClientRect();
        const defaultHeight = 40;

        if (parent.classList.contains('show')) {
            const clonedElm = hiddenCustomField.cloneNode(true);
            clonedElm.querySelectorAll('input').forEach((inp) => {
                const indx = customFieldsContainer.querySelectorAll('.custom-hidden-field');
                if (inp.name === 'custom_field_name') {
                    inp.name = `custom_field_name-${indx.length}`
                } else inp.name = `custom_field_value-${indx.length}`;
                inp.value = ''
            });
            clonedElm.querySelector('button').addEventListener('click', customFieldRemoveHandler);
            customFieldsContainer.insertAdjacentElement('beforeend', clonedElm);

            const elmCount = customFieldsContainer.querySelectorAll('.custom-hidden-field');
            parent.style.height = defaultHeight + ((boudingRect.height + 10) * elmCount.length) + 'px';
        } else {
            parent.style.height = defaultHeight + (boudingRect.height + 10) + 'px';
            parent.classList.add('show')
        }
    }

    const customFieldRemoveHandler = (e) => {
        const parent = e.target.parentElement;
        const container = parent.parentElement.parentElement;
        const elmCount = container.querySelectorAll('.custom-hidden-field');
        const height = container.getBoundingClientRect().height;
        if (elmCount.length > 1) parent.remove();
        container.style.height = (height - 50) + 'px';
        if (elmCount.length === 1) {
            parent.closest('.add-a-custom-field').classList.remove('show');
        }
    }

    const getLocation = (loc) => {
        setPickupLocation(loc);
        if(document.querySelector("select[name='location']")){
            document.querySelector("select[name='location']").value = loc.nameValue;
        }
    }

    useEffect(() => {
        const d = dragDropBox.current;

        if (d) {
            const handleDrop = (e) => {
                e.preventDefault();
                e.stopPropagation();

                const dropType = d.parentElement.dataset.type;
                const newFiles = Array.from(e.dataTransfer.files).filter((file, i) => file.type.includes("image") && i < 10);

                if (newFiles.length === 0) {
                    alert("Please drop only images!");
                    return;
                }

                setDroppedFiles((prev) => {
                    const updatedFiles = { ...prev };
                    if (!updatedFiles[dropType]) {
                        updatedFiles[dropType] = [];
                    }
                    newFiles.forEach((f) => {
                        const duplicates = updatedFiles[dropType].find((v) => {
                            if (v.filePath) {
                                return v.fileName === f.name;
                            } else return v.name === f.name;
                        });

                        if (!duplicates) updatedFiles[dropType].push(f);
                    });
                    return updatedFiles;
                });

                d.classList.remove("dragover");
            };

            const handleDragOver = (e) => {
                e.preventDefault();
                e.stopPropagation();
                d.classList.add("dragover");
            };

            const handleDragLeave = (e) => {
                e.preventDefault();
                e.stopPropagation();
                d.classList.remove("dragover");
            };

            d.addEventListener("drop", handleDrop);
            d.addEventListener("dragover", handleDragOver);
            d.addEventListener("dragleave", handleDragLeave);

            return () => {
                d.removeEventListener("drop", handleDrop);
                d.removeEventListener("dragover", handleDragOver);
                d.removeEventListener("dragleave", handleDragLeave);
            };
        }
    }, []);


    useEffect(() => {
        showLoader(true);
        const fetchProductDetails = async () => {
            const params = new URLSearchParams(location.search);
            const pid = params.get('pid') || id;

            if (!pid || pid.trim() === '') {
                showLoader(false);
                setEditId(null);
                return;
            }
            const editPr = document.querySelector('.current-page-pos-fixed') || null;
            if (editPr) editPr.innerHTML = 'Edit product';

            setEditId(pid);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/product/details`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ id: pid })
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();

                showLoader(false);
                setPrData(data);
                if (data.images) setDroppedFiles({ image: [...data.images] });
            } catch (error) {
                showLoader(false);
                console.error("Error fetching product details:", error);
            }
        };

        fetchProductDetails();
    }, [location.search, id]);
    return (
        <div className={`admin-panel-add-product${id ? ' seller-product-edit' : ''}`}>
            <div className="show-loading-win t-center" ref={loadingWin}>
                <span></span>
                <span className='mt5'>{uploadingStatus}</span>
                <div className="t-center df alic fdc product-upload-progress">
                    <progress value={progress} max={100}></progress>
                    <span>{progress}%</span>
                </div>
            </div>
            <form method="post" action="" onSubmit={(e) => e.preventDefault()} data-logid="" className="main-container" ref={formRef}>
                <div className="inp-boxes-grid-container">
                    {editId ? <span className='product-edit-mode'>Edit mode - PID: {editId}</span> : ''}
                    <div className="address-box df w100 gap20">
                        <div className="inp-box pname">
                            <input type="text" name="name" placeholder='Product Name' defaultValue={prData?.name} required={true} minLength={20} />
                        </div>
                        <div className="input-split">
                            <div className="inp-box modelname">
                                <input type="text" name="model" placeholder='Model Name' defaultValue={prData?.model} required={true} />
                            </div>
                            <div className="inp-box brand">
                                <input type="text" name="brand" placeholder='Brand Name' defaultValue={prData?.meta.brand} required={true} />
                            </div>
                        </div>
                    </div>
                    <div className="inp-four-grid df">
                        <div className="input-split price-inp">
                            <div className="inp-box dprice">
                                <input type="number" name="dprice" placeholder='Discount Price' defaultValue={prData?.dprice} inputMode="numeric" pattern="[0-9]*" />
                                <small className="tertiary-color">*Leave empty for no discount</small>
                            </div>
                            <div className="inp-box rprice">
                                <input type="number" name="price" placeholder='Regular Price' required={true} defaultValue={prData?.price} inputMode="numeric" pattern="[0-9]*" />
                            </div>
                        </div>
                        <div className="input-split">
                            <div className="inp-box size">
                                <input type="text" name="size" placeholder='Size' defaultValue={prData?.meta.size} />
                            </div>
                            <div className="inp-box color">
                                <input type="text" name="color" placeholder='Color' onChange={colorInpHandler} defaultValue={prData?.meta.color} />
                                <div className="df fdc">
                                    <div className='inp-color-display df alic gap5'></div>
                                    <small className='tertiary-color'>Use , (comma) to separate the colors <Link to={'/learn-more?tag=color-separation&from=add-product'} className='pr-color' target='_blank'>learn more</Link> about it.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className='secondary-color mb5 mt20'><small>Please enter product dimensions & weight</small></p>
                    <div className="inp-four-grid df mb20">
                        <div className="input-split price-inp">
                            <div className="inp-box width">
                                <input type="number" name="width" placeholder='Width (CM)' defaultValue={prData?.meta.width} />
                            </div>
                            <div className="inp-box height">
                                <input type="number" name="height" placeholder='Height (CM)' defaultValue={prData?.meta.height} />
                            </div>
                        </div>
                        <div className="input-split">
                            <div className="inp-box length">
                                <input type="text" name="length" placeholder='Length (CM)' defaultValue={prData?.meta.length} />
                            </div>
                            <div className="inp-box weight">
                                <input type="text" name="weight" placeholder='Weight (kg or litre)' defaultValue={prData?.meta.weight} />
                            </div>
                        </div>
                    </div>
                    <div className="inp-box w25 depertment">
                        <span>Stock Status</span>
                        <div className="stock-inp-field df gap10">
                            <div className="inp-box-inner">
                                <select name="stock" value={prData?.stock} onChange={selectChangeHandler}>
                                    <option value="in">In Stock</option><option value="out">Out Of Stock</option>
                                </select>
                            </div>
                            <div className="inp-box">
                                <input type="number" name="total_stock" placeholder='Total Stock' inputMode="numeric" pattern="[0-9]*" />
                            </div>
                        </div>
                    </div>
                    <div className="inp-box w25 category-inp-field">
                        <span>Category</span>
                        <div className="inp-box-inner">
                            <select name="category" value={prData?.category} onChange={selectChangeHandler} required={true}>
                                <option value="">Select Category</option>
                                {categories && categories.map((category, i) => <option key={i} value={category.toLowerCase()}>{category}</option>) }
                                <option value="rq">+ Add Category</option>
                            </select>
                        </div>
                        <div className="category-add-request mt10">
                            <div className="inp-box m0">
                                <div className="df gap10">
                                    <input type="text" placeholder='Type Your Category Name' className='w100' name='rq_cateogry_name' />
                                    <input type="button" value={'Submit'} className='c-pointer' onClick={categorySubmitHandler} />
                                </div>
                            </div>
                            <span className='tertiary-color mt5'><small>Please wait for confirmation, our team will review this requested category name and after accepting the new category name will show for all users & sellers, you'll receive a notification with a confirmation email.</small></span>
                        </div>
                    </div>
                    <div className="inp-box w25">
                        <span>Pickup Location</span>
                        <div className="inp-box-inner">
                            <select name="location" value={!pickupLocation ? prData?.location : pickupLocation.nameValue} onChange={selectChangeHandler}>
                                <option value="anywhere">Anywhere</option>
                                <option value="CCANDITC">Cumilla Computer &amp; IT Center</option>
                                { pickupLocation && pickupLocation.name ? <option value={pickupLocation.nameValue}>{pickupLocation.name}</option> : ''}
                                <option value="add_loc">Add Location</option>
                            </select>
                        </div>
                        <div className="hidden-loc-map">
                            <MapPreviewComponent getLocPos={getLocation} />
                        </div>
                    </div>
                    <div className="inp-box w25 warranty">
                        <span>Warranty</span>
                        <div className="inp-box-inner">
                            <select name="warranty" value={prData?.warranty} onChange={selectChangeHandler} required={true}>
                                <option value="">Select Warranty</option>
                                <option value="sd">Same Day</option>
                                <option value="no">No Warranty</option>
                                <option value="3mnth">3 Months</option>
                                <option value="6mnth">6 Months</option>
                                <option value="1yr">1 Year</option>
                                <option value="2yr">2 Years</option>
                                <option value="3yr">3 Years</option>
                            </select>
                        </div>
                    </div>
                    <div className="inp-box size add-a-custom-field">
                        <div className="custom-field-heading df alic jstfy-btwn w100 secondary-color">
                            <div>
                                <h4>Add a custom field</h4>
                                <small className='tertiary-color'>This will be shown as your product details</small>
                            </div>
                            <div className="add-custom-field-btn df alic jstfy-c c-pointer" onClick={addCustomFieldHandler}>
                                <PlusIcon width={22} height={22} />
                            </div>
                        </div>
                        <div className="custom-hidden-fields-container">
                            <div className="custom-hidden-field tertiary-color">
                                <div className="inp-box custom-field">
                                    <input type="text" name="custom_field_name" data-field="custom" placeholder='Field Name' />
                                </div>
                                <div className="inp-box rprice">
                                    <input type="text" name="custom_field_value" data-field="custom" placeholder='Field Value' />
                                </div>
                                <button className='custom-field-remove df alic jstfy-c' onClick={customFieldRemoveHandler}>
                                    <span className="minus"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="inp-box desc-and-feature">
                        <textarea className="desc-feature-inp" placeholder='Write your product description...' minLength={50} name="description" defaultValue={prData ? prData.description : ''} required={true} />
                        <span className='tertiary-color'>
                            <small>*Tips: If you want to show <b>Bullet Points</b> on your description then write like this <br />*WRITE YOUR BULLET POINT TEXT<br />*WRITE YOUR BULLET POINT TEXT<br />This will show on your description with bullet points, here you can <Link to={'/learn-more?tag=description-bullet-points&from=add-product'} className='pr-color' target='_blank'>learn more</Link> about it.</small>
                        </span>
                    </div>
                    <div className="inp-box media-drag-drop" data-type="image" multiple="" accept="image/*" >
                        <div className="drag-drop-box" ref={dragDropBox}>
                            <div className="box-inner">
                                <div className="mid-content">
                                    <span>Drag And Drop here.</span>
                                    <br />
                                    <span>or</span>
                                    <div className="inn-media-inp">
                                        <span>Select file</span>
                                        <input type="file" accept='image/*' multiple onChange={fileChangeHandler} />
                                    </div>
                                </div>
                                <span className='image-upload-note tertiary-color'>*Min 2 and Max 10 images</span>
                            </div>
                        </div>
                        <div className="media-display">
                            {
                                droppedFiles && droppedFiles.image ? droppedFiles.image.map((img, i) => {
                                    return <div className="media-img-container" key={i} >
                                        <span className='delete-media-img pa' onClick={handleProductImgRemove} data-fname={img.filePath ? img.fileName : img.name}>Remove</span>
                                        <img draggable={true} src={img.filePath ? process.env.REACT_APP_API_URL + '/' + img.filePath : URL.createObjectURL(img)} id={Date.now()} data-filename={img.name} alt="product" onDragStart={dragStartHandle} />
                                    </div>
                                }) : ''
                            }
                        </div>
                    </div>
                    <div className="add-product-submit-btn">
                        <button className="product-add-submit-btn button-with-loading-anim" onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </form>
        </div>
    )
}