export const showLoader = (show, full) => {
    let loaderOuter = document.querySelector('.loader-outer');
    if (!loaderOuter) return;

    if(full === 'processing' || loaderOuter.dataset.type === 'processing'){
        if(show && full === 'processing') {
            loaderOuter.dataset.type = 'processing';
            loaderOuter.classList.add('transparent')
            loaderOuter.classList.remove('hide');
        }else if(full === 'processing'){
            loaderOuter.dataset.type = '';
            loaderOuter.classList.remove('transparent');
            loaderOuter.classList.add('hide');
        }
    }else{
        if (show) {
            if(!full) loaderOuter.classList.add('transparent');
            loaderOuter.classList.remove('hide');
        } else {
            loaderOuter.classList.remove('transparent');
            loaderOuter.classList.add('hide');
        }
    }
};