class ApiService {
    constructor() {
        // this.baseURL = process.env.REACT_APP_API_URL;
        this.baseURL = window.location.hostname === 'localhost' ? 'http://localhost:4000' : process.env.REACT_APP_API_URL;
    }

    /**
    * Refreshes the access token by making a request to /token/refresh.
    * @returns {Promise<string>} - A promise that resolves to the new token.
    */
    async refreshToken() {
        try {
            const response = await fetch(`${this.baseURL}/api/v1/user/token/refresh`, {
                method: 'POST',
                credentials: 'include',  // Make sure to send cookies with this request
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({})
            });

            if(response.status === 400) window.location.href = '/';

            if (!response.ok) {
                throw new Error(`Error refreshing token: ${response.status} ${response.statusText}`);
            }

            const data = await response.json();
            return data.token;  // Assuming the response contains the new token in a 'token' field
        } catch (error) {
            console.error('Token refresh failed:', error);
            throw error;
        }
    }

    /**
     * Handles GET requests
     * @param {string} endpoint - The API endpoint
     * @param {Object} params - Query parameters to be added to the URL
     * @returns {Promise<Object>} - The response data
     */
    async get(endpoint, params = {}) {
        try {
            const url = new URL(`${this.baseURL}${endpoint}`);
            Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

            let response = await fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                if (response.status === 401 || response.status === 403) {
                    // Refresh the token
                    const newToken = await this.refreshToken();

                    if(!newToken && endpoint.split('/').includes('seller')){
                        localStorage.removeItem('sdt');
                        window.location.href = '/seller';
                    }else if(!newToken && endpoint.split('/').includes('user')){
                        localStorage.removeItem('wishlist')
                        localStorage.removeItem('ud');
                        localStorage.removeItem('initd_ord');
                        window.location.href = '/account';
                    }

                    // Retry the request with the new token
                    response = await fetch(url, {
                        method: 'GET',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${newToken && newToken.token ? newToken.token : newToken}`, // Include the new token
                        },
                    });
                } else {
                    throw new Error(`Error ${response.status}: ${response.statusText}`);
                }
            }

            return await response.json();
        } catch (error) {
            console.error("GET Request Error:", error);
            throw new Error("Failed to fetch data");
        }
    }

    /**
     * Handles POST requests
     * @param {string} endpoint - The API endpoint
     * @param {Object} data - The data to be sent in the request body
     * @returns {Promise<Object>} - The response data
     */
    async post(endpoint, data) {
        try {
            const uri = `${this.baseURL}${endpoint}`;
            const jsonString = JSON.stringify(data);

            let response = await fetch(uri, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: jsonString,
            });

            const dt = await response.json();

            if (!response.ok) {
                if(dt.redirect && dt.redirect !== '' && response.status !== 403) {
                    // window.location.href = dt.redirect;
                }else if (response.status === 401 || response.status === 403) {
                    // Refresh the token
                    const newToken = await this.refreshToken();
                    
                    if(!newToken && endpoint.split('/').includes('seller')){
                        localStorage.removeItem('sdt');
                        window.location.href = '/seller';
                    }else if(!newToken && endpoint.split('/').includes('user')){
                        localStorage.removeItem('wishlist')
                        localStorage.removeItem('ud');
                        localStorage.removeItem('initd_ord');
                        window.location.href = '/account';
                    }
                    // Retry the request with the new token
                    response = await fetch(uri, {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${newToken && newToken.token ? newToken.token : newToken}`, // Include the new token
                        },
                        body: jsonString,
                    });
                } else {
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                    throw new Error(`Error ${response.status}: ${response.statusText}`);
                }
            }
            return dt;
        } catch (error) {
            console.log("POST Request Error:", error);
        }
    }
}

const apiService = new ApiService();

export default apiService;