import { useQuery } from "@tanstack/react-query";
import apiService from "../services/apiService";

const fetchCategories = async () => {
    const res = await apiService.get('/api/v1/categories');
    return res;
};

export function useCategoryList() {
    return useQuery({
        queryKey: ['categories'], // Unique key for categories
        queryFn: fetchCategories,
        staleTime: 1000 * 60 * 10, // Cache for 10 minutes
    });
}
