import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AddProductAdmin from './components/admin/AddProduct';
import { WebSocketProvider } from './services/WebSocketProvider';
import { showLoader } from './utils/loaderUtils';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './styles/default.css';


const SavedIcon = lazy(() => import('./assets/icons/saved.svg').then(module => ({ default: module.ReactComponent })));
const ErrorIcon = lazy(() => import('./assets/icons/error.svg').then(module => ({ default: module.ReactComponent })));
const queryClient = new QueryClient();

const Home = lazy(() => import('./pages/Home'));
const Cart = lazy(() => import('./pages/Cart'));
const Profile = lazy(() => import('./pages/Profile'));
const User = lazy(() => import('./pages/small/UserLogin'));

const Admin = lazy(() => import('./pages/Admin'));
const Tools = lazy(() => import('./pages/Tools'));
const Product = lazy(() => import('./pages/Product'));
const Services = lazy(() => import('./pages/Services'));
const Search = lazy(() => import('./pages/small/Search'));
const BuyAndSell = lazy(() => import('./pages/BuyAndSell'));
const AdminLogin = lazy(() => import('./components/admin/AdminLogin'));
const OrderProgressUpdate = lazy(() => import('./components/admin/OrderProgressUpdate'));

// seller
const Seller = lazy(() => import('./pages/Seller'));
const SellerPreview = lazy(() => import('./components/seller/preview/SellerPreview'));
const Dashboard = lazy(() => import('./components/seller/dashboard/Dashboard'));
const Overview = lazy(() => import('./components/seller/overview/Overview'));
const Products = lazy(() => import('./components/seller/products/Products'));
const Orders = lazy(() => import('./components/seller/orders/Orders'));
const Reviews = lazy(() => import('./components/seller/reviews/Reviews'));

// small
const Header = lazy(() => import('./components/Header'));
const Feedback = lazy(() => import('./pages/small/Feedback'));
const LearnMore = lazy(() => import('./pages/small/LearnMore'));
const ChatWithUs = lazy(() => import('./pages/small/ChatWithUs'));
const CustomOrder = lazy(() => import('./pages/small/CustomOrder'));
const NotFound = lazy(() => import('./components/mini/404/NotFound'));
const CookiePopup = lazy(() => import('./components/data/CookiePopup'));
const ChatComponent = lazy(() => import('./pages/small/ChatComponent'));
const ForgotPassword = lazy(() => import('./pages/small/ForgotPassword'));
const PrivacyPolicy = lazy(() => import('./components/data/PrivacyPolicy'));
const TrackOrder = lazy(() => import('./components/mini/order/TrackOrder'));
const VerifyEmail = lazy(() => import('./components/redirects/VerifyEmail'));
const TermsAndConditions = lazy(() => import('./components/data/TermsAndConditions'));


function App() {
  console.log('helo from app.js');
  const location = useLocation();
  const [showCookiePopup, setShowCookiePopup] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('ud')) || { id: '', name: '', email: '', profileImg: '' });
  const useHeader = ['', 'cart', 'search', 'services', 'custom', 'profile', 'tools', 'buy-and-sell', 'seller', 'feedback', 'live-support', 'forgot-password', 'password-change', 'seller/forgot-password'];
  // const useHeader = ['', 'cart', 'search', 'services', 'custom', 'profile', 'tools', 'buy-and-sell', 'seller', 'feedback', 'live-support', 'forgot-password', 'password-change', 'seller/forgot-password', 'terms-and-conditions', 'privacy-policy'];
  const normalizedPath = location.pathname.replace(/^\/|\/$/g, '');
  let showHeader = useHeader.some(path => normalizedPath === path && normalizedPath.match('panel') == null) || /product/.test(location.pathname) || /order/.test(location.pathname) || location.pathname.slice(1).split('/')[0] === 's';

  // location.pathname.slice(1).split('/')[0] === 'admin'
  if (location.pathname === '/seller/panel/products' || location.pathname === '/seller/panel/products/') {
    showHeader = false;
  } else if (location.pathname.slice(1).split('/').length > 2 && location.pathname.slice(1).split('/')[0] === 'seller') showHeader = false;

  const userDataUpdate = (data) => setUser(data);

  useEffect(() => {
    showLoader(true);
    const dt = JSON.parse(localStorage.getItem('ud'));
    const cookieConsent = localStorage.getItem("cookieConsent");

    if (!cookieConsent) setShowCookiePopup(true);
    if (dt && dt.id === '') setUser(dt);

    // loading handler
    const loadingHandler = () => {
      let activeRequests = 0;

      // Observe Fetch API
      const originalFetch = window.fetch;
      window.fetch = async function (...args) {
        activeRequests++;
        try {
          const response = await originalFetch.apply(this, args);
          return response;
        } finally {
          activeRequests--;
        }
      };

      // Observe XHR Requests
      const originalXHROpen = XMLHttpRequest.prototype.open;
      XMLHttpRequest.prototype.open = function (...args) {
        this.addEventListener("loadend", () => activeRequests--);
        activeRequests++;
        return originalXHROpen.apply(this, args);
      };

      function observeElements() {
        function trackElementLoading(element, attr) {
          const originalSet = Object.getOwnPropertyDescriptor(element.prototype, attr).set;
          Object.defineProperty(element.prototype, attr, {
            set: function (value) {
              activeRequests++;
              this.addEventListener("load", () => activeRequests--);
              this.addEventListener("error", () => activeRequests--);
              originalSet.call(this, value);
            }
          });
        }
        trackElementLoading(HTMLImageElement, "src"); // Images
        trackElementLoading(HTMLScriptElement, "src"); // Scripts
        trackElementLoading(HTMLLinkElement, "href"); // Stylesheets & Fonts
      }
      observeElements();

      // Function to check if network is idle
      function isNetworkIdle() {
        return activeRequests === 0;
      }
      let count = 0;

      // Periodically check
      const interval = setInterval(() => {
        // console.log("Active Requests:", activeRequests);
        count++;
        const idle = isNetworkIdle();
        if (idle || count > 100 || activeRequests < 1) {
          if (location.pathname.split('/')[1] === 'product') return;
          activeRequests = 0;
          showLoader(false)
          showLoader(false);
          clearInterval(interval);
        } else {
          if (location.pathname.slice(1).split('/')[0] === 's') {
            showLoader(true, true);
          } else showLoader(true);
        }
      }, 100);
    }
    loadingHandler();
    if(location.pathname === '/account'){
      document.body.style.background = "var(--primaryColor)";
    }else document.body.style = "";
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [location.pathname]);
  return (
    <WebSocketProvider>
    <>
      <QueryClientProvider client={queryClient}>
        <div className='notification-main'>
          <div className="notif-icon-container">
            <Suspense fallback={<div>Loading icon...</div>}>
              <span className='savedIcon show'>
                <SavedIcon width={25} height={25} />
              </span>
              <span className='errorIcon dn'>
                <ErrorIcon width={25} height={25} />
              </span>
            </Suspense>
          </div>
          <div className="notif-content-wrapper">
            <p className='title'></p><p className='message'></p>
          </div>
          <div className="notif-action-btns fdc"></div>
        </div>
        {
          showCookiePopup && (
            <Suspense fallback={<></>}>
              <CookiePopup />
            </Suspense>
          )
        }
        <Suspense fallback={<></>}>
          {showHeader && <Header data={user} />}
          <Routes>
            {/* =================== Main Routes =================== */}
            <Route path='/' element={<Home />} />
            <Route path='/cart' element={<Cart user={user} />} />
            <Route path='/product/:name' element={<Product />} />
            <Route path='/search' element={<Search />} />
            <Route path='/services' element={<Services />} />
            <Route path='/account' element={<User update={userDataUpdate} />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/password-change' element={<ForgotPassword />} />
            <Route path='/custom' element={<CustomOrder />} />
            <Route path='/profile' element={<Profile data={user} update={userDataUpdate} />} />
            <Route path='/order/:id' element={<TrackOrder />} />
            <Route path='/tools' element={<Tools />} />
            <Route path='/buy-and-sell' element={<BuyAndSell />} />

            {/* =================== Seller Routes =================== */}
            <Route path='/seller' element={<Seller />} />
            <Route path='/s/:id' element={<SellerPreview />} />
            <Route path='/seller/forgot-password' element={<ForgotPassword />} />
            <Route path="/seller/panel" element={<Dashboard />}>
              <Route index element={<Overview />} />
              <Route path="products" element={<Products />} />
              <Route path="products/edit/:id" element={<AddProductAdmin />} />
              <Route path="orders" element={<Orders />} />
              <Route path="reviews" element={<Reviews />} />
            </Route>

            {/* =================== Admin Routes =================== */}
            <Route path='/admin' element={<Admin />} />
            <Route path='/admin/order/:id/progress' element={<OrderProgressUpdate />} />
            <Route path='/admin/login' element={<AdminLogin />} />

            {/* =================== Small Page Routes =================== */}
            <Route path='/feedback' element={<Feedback />} />
            <Route path='/live-support' element={<ChatWithUs />} />
            <Route path='/live-support/chat' element={<ChatComponent />} />
            <Route path='/learn-more' element={<LearnMore />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

            {/* =================== error 404 page =================== */}
            <Route path='*' element={<NotFound />} />
          </Routes>
        </Suspense>
      </QueryClientProvider>
    </>
    </WebSocketProvider>
  );
}

export default App;





