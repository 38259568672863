import React, { useEffect, useMemo, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents } from 'react-leaflet';
import { useWebSocket } from '../../../services/WebSocketProvider';
import { showLoader } from '../../../utils/loaderUtils';


const AutoZoom = ({ start, end }) => {
    const map = useMap();

    useEffect(() => {
        if (!map || !start || !end) return;

        const bounds = L.latLngBounds([start, end]);

        map.fitBounds(bounds, { padding: [50, 50] });
    }, [map, start, end]);

    return null;
};

const ClickableMap = ({ set, details, location }) => {
    useMapEvents({
        click: async (e) => {
            showLoader(true, 'processing');
            const { lat, lng } = e.latlng;
            // Use fetch to get the address (same as previous)
            try {
                const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`);
                if (!response.ok) {
                    throw new Error('Failed to fetch address');
                }

                const data = await response.json();

                // Update clicked location with lat, lng and address
                showLoader(false, 'processing');
                location({
                    cords: [lat, lng],
                    name: data.display_name,
                    nameValue: data.display_name.replaceAll(', ', '-').replaceAll(' ', '-')
                });
                details(data.display_name)
                set([lat, lng]);
            } catch (error) {
                console.error('Error fetching address:', error);
            }
        },
    });
    return null;
};

const MapPreviewComponent = ({ oid, data, getLocPos }) => {
    const start = useMemo(() => [23.460569558530977, 91.180943770236], []);
    const [locationDetails, setLocationDetails] = useState('Rider current location')
    const [location, setLocation] = useState(data && data.meta && data.meta.delivery_progress ? data.meta.delivery_progress.rider.location : '');
    const { socket } = useWebSocket();

    socket.onmessage = (event) => {
        const blob = event.data;

        const reader = new FileReader();
        reader.onload = function () {
            const message = JSON.parse(reader.result) || {};
            if (message.type === 'order_progress' && message.oid === oid) {
                if (message.data.rider.loc[0]) {
                    setLocation(message.data.rider.loc);
                }
            }
        };

        reader.readAsText(blob);
    }

    var cssIcon = L.divIcon({
        className: 'css-icon',
        html: '<div class="gps_ring"></div>'
        , iconSize: [22, 22]
    });

    const serviceArea = [
        [23.35, 91.05],
        [23.65, 91.30]
    ]
    return (
        <div className={getLocPos ? 'pt10' : ''}>
            {
                !getLocPos && <div className="t-center">
                    <p className="tertiary-color mb5">Check riders live location</p>
                </div>
            }
            <MapContainer
                center={start}
                zoom={15}
                minZoom={10}
                maxZoom={19}
                zoomControl={false}
                attributionControl={false}
                maxBounds={serviceArea}
                maxBoundsViscosity={1.0}
                style={{ height: '300px', borderRadius: '5px', zIndex: 0 }}
            >
                {getLocPos && <ClickableMap details={setLocationDetails} set={setLocation} location={getLocPos} />}
                <TileLayer url={'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png'} />
                {location && !getLocPos ? <AutoZoom start={location} end={start} /> : ''}

                {location && (
                    <Marker icon={cssIcon} position={location}>
                        <Popup>{locationDetails}</Popup>
                    </Marker>
                )}
            </MapContainer>
        </div>
    );
};

export default MapPreviewComponent;